import moment from "moment";
import {isVideo} from "utils";
import {TestimonialModel} from "interfaces";
import {Column, Typography} from "components";
import {Container, Grid, Video, Image} from "./styled";

interface WatchTestimonialProps {
  testimonial: TestimonialModel;
}

export const WatchTestimonial: React.FC<WatchTestimonialProps> = ({
  testimonial,
}) => {
  const {media, message, created_at} = testimonial;
  const src = media[0];
  return (
    <Container>
      <Grid>
        {isVideo(src) ? (
          <Video controls autoPlay src={media[0]} />
        ) : (
          <Image src={src} />
        )}
        <Column gap={5}>
          <Typography>{moment(created_at).fromNow()}</Typography>
          <Typography fontFamily="Poppins-Medium">{message}</Typography>
        </Column>
      </Grid>
    </Container>
  );
};
