import moment from "moment";
import {isVideo} from "utils";
import {useEffect} from "react";
import {AwardNumber} from "interfaces";
import {useTheme} from "styled-components";
import {Image, ThumbsUp, Trash, Video} from "react-feather";
import {get, remove} from "@redux/slices/testimonials/thunks";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  Table,
  Header,
  Column,
  Confirm,
  Skeleton,
  IconButton,
  Typography,
  ComponentLoader,
  WatchTestimonial,
} from "components";

interface TestimonialsProps {}

export const Testimonials: React.FC<TestimonialsProps> = () => {
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {container, white, blackWhite, heart} = useTheme();
  const {testimonials, request} = useAppSelector("testimonials");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column>
      <Header children="Testimonios" icon={ThumbsUp} />
      <ComponentLoader
        requestStatus={request["testimonials"]}
        emptyUI={{
          data: testimonials,
          style: {width: "100%"},
          message: "No hay testimonios súbidos aún",
          icon: <ThumbsUp size={40} color={blackWhite} />,
        }}
        skeleton={
          <Column width="100%">
            <Skeleton width={300} height={50} />
            <Column width="100%">
              <Skeleton width="70%" height={40} />
              <Column gap={1} width="100%">
                <Skeleton count={5} width="100%" height={80} />
              </Column>
            </Column>
          </Column>
        }
      >
        {testimonials.map(({lottery, awards}) => (
          <Column marginBottom={15} key={lottery["_id"]}>
            <Typography fontFamily="Poppins-Medium" fontSize={16}>
              #{lottery?.["number"]} - {lottery?.["title"]}
            </Typography>
            <Table
              data={awards}
              columns="120px 350px 200px 140px"
              head={[
                {label: "Fecha"},
                {label: "Mensaje"},
                {label: "Premio"},
                {label: "Acciones"},
              ]}
            >
              {({title, number}) => {
                const testimonial = (number as AwardNumber)["testimonial"];
                const src = testimonial["media"][0];
                const PreviewIcon = isVideo(src) ? Video : Image;
                return (
                  <Table.Tr key={title} backgroundColor={container}>
                    <Table.Td>
                      <Typography>
                        {moment(testimonial["createdAt"]).format("DD/MM/YYYY")}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography>{testimonial["message"]}</Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography>{title}</Typography>
                    </Table.Td>
                    <Table.Td>
                      <IconButton
                        toolTip="Ver testimonio"
                        icon={<PreviewIcon color={white} />}
                        onClick={() =>
                          openModal(
                            <WatchTestimonial testimonial={testimonial} />
                          )
                        }
                      />
                      <IconButton
                        toolTip="Eliminar"
                        icon={<Trash color={heart} />}
                        toolTipStyle={{backgroundColor: heart}}
                        onClick={() => {
                          openModal(
                            <Confirm
                              message="Ya no podrás revertir esta acción"
                              title="Seguro que deseas eliminar testimonio?"
                              actions={[
                                {
                                  label: "Eliminar",
                                  loaderId: "remove-testomonial",
                                  leftIcon: <Trash color={white} />,
                                  style: {backgroundColor: heart},
                                  onClick: () =>
                                    dispatch(remove(testimonial["_id"])),
                                },
                                {
                                  label: "Cancelar",
                                  onClick: () => closeModal(),
                                  style: {backgroundColor: "#bcbcbcec"},
                                },
                              ]}
                            />
                          );
                        }}
                      />
                    </Table.Td>
                  </Table.Tr>
                );
              }}
            </Table>
          </Column>
        ))}
      </ComponentLoader>
    </Column>
  );
};
