import {api} from "api";
import {State} from "./types";
import {toggleButtonLoaderId} from "../layout";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "testimonials/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<State["testimonials"]>(
        "/lottery/getTestimonial?limit=10&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const remove = createAsyncThunk(
  "testimonials/remove",
  async (id: string, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("remove-testomonial"));
    try {
      await api.Delete<State["testimonials"]>(
        `/lottery/removeTestimonial/${id}`
      );
      window.location.reload();
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-testomonial"));
    }
  }
);
