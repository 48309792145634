import {Container, Grid} from "./styled";
import {useTheme} from "styled-components";
import {AwardModel, LotteryModel} from "interfaces";
import {Gift, Info, Plus, Trash} from "react-feather";
import {removeAward} from "@redux/slices/lottery/thunks";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {Dot} from "components/@app-layout/Layout/lib/TopBar/styled";
import {
  Row,
  Line,
  Column,
  Confirm,
  NewAward,
  HasAccess,
  IconButton,
  Multimedia,
  Typography,
  EmptyUIState,
} from "components";

export type AwardCRUD = AwardModel & {files?: File[]};

const newAward: AwardCRUD = {
  _id: "",
  title: "",
  media: [],
  files: [],
  number: null,
  position: "",
  description: "",
  characteristics: [],
};

interface AwardsProps {
  lottery: LotteryModel;
}

export const Awards: React.FC<AwardsProps> = ({lottery}) => {
  const {awards, _id: lotteryId} = lottery;
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {white, primary, heart} = useTheme();
  const {isSuperAdmin} = useAppSelector("auth");

  return (
    <Column width="100%">
      <Row gap={10}>
        <Row gap={10}>
          <Typography fontSize={18} fontFamily="Poppins-SemiBold">
            ({awards["length"]})
          </Typography>
          <HasAccess only={isSuperAdmin}>
            <IconButton
              size={35}
              backgroundColor={primary}
              icon={<Plus size={25} color={white} />}
              onClick={() => {
                openModal(
                  <NewAward lottery={lottery} initialState={newAward} />
                );
              }}
            />
          </HasAccess>
        </Row>
        <Dot />
      </Row>
      <EmptyUIState
        data={awards}
        icon={<Gift size={45} color={white} />}
        style={{width: "100%", paddingBottom: "2rem"}}
        message="Aún no has agregado premios a este sorteo. Presiona en el más (+) para hacerlo"
      >
        <Grid>
          {awards.map(
            (
              {_id, title, description, media, position, characteristics = []},
              key
            ) => (
              <Column key={key} style={{transform: "rotateX(180deg)"}}>
                <Row width="100%" justifyContent="space-between">
                  <Typography fontSize={18} fontFamily="Poppins-Medium">
                    Premio al ganador #{position}
                  </Typography>
                  <HasAccess only={isSuperAdmin}>
                    <Row>
                      <IconButton
                        toolTip="Eliminar"
                        toolTipPosition="down"
                        loaderId="remove-award"
                        icon={<Trash color={heart} />}
                        toolTipStyle={{backgroundColor: heart}}
                        onClick={() => {
                          openModal(
                            <Confirm
                              title="¿Seguro que deseas eliminarlo?"
                              message="Ya no podrás revertir esta acción"
                              actions={[
                                {
                                  label: "Eliminar",
                                  loaderId: "remove-award",
                                  leftIcon: <Trash color={white} />,
                                  style: {backgroundColor: heart},
                                  onClick: () =>
                                    dispatch(
                                      removeAward({awardId: _id, lotteryId})
                                    ),
                                },
                                {
                                  label: "Cancelar",
                                  onClick: () => closeModal(),
                                  style: {backgroundColor: "#bcbcbcec"},
                                },
                              ]}
                            />
                          );
                        }}
                      />
                    </Row>
                  </HasAccess>
                </Row>
                <Container>
                  <Multimedia type="view-only" media={media} />
                  <Column marginTop={15} width="100%">
                    <Column gap={0}>
                      <Typography fontFamily="Poppins-Medium">
                        {title}
                      </Typography>
                      <Typography fontFamily="Poppins-Light" fontSize={13}>
                        Título
                      </Typography>
                    </Column>
                    <Column gap={0}>
                      <Typography fontFamily="Poppins-Medium">
                        {description}
                      </Typography>
                      <Typography fontFamily="Poppins-Light" fontSize={13}>
                        Descripción
                      </Typography>
                    </Column>
                  </Column>
                  <Line />
                  <EmptyUIState
                    data={characteristics}
                    style={{padding: ".5rem 0"}}
                    icon={<Info color={white} size={30} />}
                    message="No hay características para este premio"
                  >
                    <Column width="100%">
                      <Row gap={10}>
                        <Typography fontFamily="Poppins-Medium" fontSize={16}>
                          Características
                        </Typography>
                      </Row>
                      {characteristics.map(
                        ({title, description}, characteristicIndex, arr) => (
                          <Column
                            gap={0}
                            width="100%"
                            key={characteristicIndex}
                          >
                            <Column gap={0}>
                              <Typography fontFamily="Poppins-Medium">
                                {title}
                              </Typography>
                              <Typography
                                fontFamily="Poppins-Light"
                                fontSize={13}
                              >
                                {`Título #${characteristicIndex + 1}`}
                              </Typography>
                            </Column>
                            <Column gap={0}>
                              <Typography fontFamily="Poppins-Medium">
                                {description}
                              </Typography>
                              <Typography
                                fontFamily="Poppins-Light"
                                fontSize={13}
                              >
                                {`Descripción #${characteristicIndex + 1}`}
                              </Typography>
                            </Column>

                            {arr["length"] !== key + 1 && (
                              <Line margin=".5rem auto 0" width="60%" />
                            )}
                          </Column>
                        )
                      )}
                    </Column>
                  </EmptyUIState>
                </Container>
              </Column>
            )
          )}
        </Grid>
      </EmptyUIState>
    </Column>
  );
};
