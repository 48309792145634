import styled from "styled-components";

export const Container = styled.div`
  width: 700px;
  padding: 2rem 1rem;
  border-radius: 10px;
  background-color: ${({theme}) => theme["container"]};

  ${({theme}) => theme.breakpoints.up("sm")} {
    width: 650px;
    padding: 1rem;
  }
`;

export const Grid = styled.div`
  gap: 1rem;
  width: 100%;
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 250px 1fr;
`;

export const Video = styled.video`
  height: 400px;
  width: 100%;
`;

export const Image = styled.img`
  height: 400px;
  width: 100%;
`;
