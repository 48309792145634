import {GetPaymentsFilter} from "./types";
import {initialState} from "./initial-state";
import {changePaymentStatus, get} from "./thunks";
import {setRequestStatus} from "@redux/request-status";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setFilter: function (state, action: PayloadAction<GetPaymentsFilter>) {
      state["filter"] = action["payload"];
    },
  },
  extraReducers: function (builder) {
    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["payments"] = data;
      state["request"]["payments"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["payments"] = setRequestStatus("error");
    });

    builder.addCase(changePaymentStatus.fulfilled, function (state, action) {
      const updated = action.payload;
      const paymentsUpdated = state["payments"];
      const index = paymentsUpdated.findIndex(
        ({_id}) => _id === updated["_id"]
      );
      paymentsUpdated[index] = updated;
      state["payments"] = paymentsUpdated;
    });
  },
});

export const {reducer: payments} = paymentSlice;
export const {setFilter} = paymentSlice["actions"];
