import {Award} from "react-feather";
import {LotteryModel} from "interfaces";
import {useTheme} from "styled-components";
import {Avatar, Column, EmptyUIState, Row, Typography} from "components";

interface WinnersProps {
  lottery: LotteryModel;
}

export const Winners: React.FC<WinnersProps> = ({lottery}) => {
  const {white} = useTheme();
  const winners = lottery["awards"].filter(({number}) => {
    if (number && number["userFound"]) return number["userFound"];
  });

  return (
    <Column>
      <EmptyUIState
        data={winners}
        style={{width: "100%"}}
        message="No hay ganadores por ahora."
        icon={<Award color={white} size={40} />}
      >
        {winners.map(({number, position}, key) => {
          const winner = number!["userFound"];
          return (
            <Row gap={10} key={key}>
              <Avatar size={35} src={winner["picture"]} />
              <Column gap={0}>
                <Typography fontFamily="Poppins-Medium">
                  {winner["name"]} {winner["lastname"]}
                </Typography>
                <Row>
                  <Typography>Posición: #{position}</Typography>
                </Row>
              </Column>
            </Row>
          );
        })}
      </EmptyUIState>
    </Column>
  );
};
