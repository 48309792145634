import {Remove} from "./lib";
import {isVideo} from "utils";
import {useTheme} from "styled-components";
import {useEffect, useRef, useState} from "react";
import {AddPhotoAlternate} from "@mui/icons-material";
import {Container, Image, Media, Video} from "./styled";

interface MultimediaProps {
  media: string[];
  multiple?: boolean;
  type?: "view-only" | "editable";
  onNewMedia?: (files: File[]) => void;
}

export const Multimedia: React.FC<MultimediaProps> = ({
  media,
  onNewMedia,
  multiple = true,
  type = "editable",
}) => {
  const {white05} = useTheme();
  const inputFile = useRef<HTMLInputElement>(null);
  const [previews, setPreviews] = useState<{src: string; name: string}[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  let isEditable = type === "editable";

  if (!multiple) {
    isEditable = media["length"] === 0 && previews["length"] === 0;
  }

  useEffect(() => {
    if (onNewMedia) onNewMedia(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <Container>
      <input
        id="file"
        type="file"
        ref={inputFile}
        style={{display: "none"}}
        accept="image/png, image/gif, image/jpeg, video/mp4, video/quicktime"
        onChange={({target}) => {
          const {files} = target;
          if (files) {
            const file = files[0];
            setFiles((prev) => [...prev, file]);
            setPreviews((prev) => [
              ...prev,
              {src: URL.createObjectURL(file), name: file["name"]},
            ]);
          }
        }}
      />
      {media.map((src, key) => {
        src = typeof src === "string" ? src : URL.createObjectURL(src);
        const isVideoFormat = isVideo(src);
        return (
          <Media
            key={key}
            style={{
              width: isVideoFormat ? 140 : 90,
              height: isVideoFormat ? 150 : 90,
            }}
          >
            {isVideoFormat ? <Video controls src={src} /> : <Image src={src} />}
            {isEditable && <Remove onClick={() => {}} />}
          </Media>
        );
      })}
      {previews.map(({src, name}, key) => {
        let isVideoPreview = isVideo(name);
        return (
          <Media
            key={key}
            style={{
              width: isVideoPreview ? 140 : 90,
              height: isVideoPreview ? 150 : 90,
            }}
            onClick={() => {
              if (inputFile["current"] && !src) inputFile["current"]["click"]();
            }}
          >
            {isVideoPreview ? (
              <Video controls src={src} />
            ) : (
              <Image src={src} />
            )}
            <Remove
              onClick={() => {
                setPreviews((prev) =>
                  prev.filter((item) => item["src"] !== src)
                );
                setFiles((prev) =>
                  prev.filter((item) => item["name"] !== name)
                );
              }}
            />
          </Media>
        );
      })}
      {isEditable && (
        <Media
          onClick={() => {
            if (inputFile["current"]) inputFile["current"]["click"]();
          }}
        >
          <AddPhotoAlternate style={{fontSize: 40, color: white05}} />
        </Media>
      )}
    </Container>
  );
};
